import { Button, INodeProps, Module, Node } from '@msdyn365-commerce-modules/utilities';
import MsDyn365 from '@msdyn365-commerce/core';
import * as React from 'react';
import { IProductCollectionViewProps, IProductComponentViewProps } from './mfrm-recently-viewed-products';

const ProductCollectionView: React.FC<IProductCollectionViewProps> = props => {
    const { heading, ProductCollectionContainer, products, SingleSlideCarouselComponentProps, GridComponentProps, isCarousel } = props;
    if (products && MsDyn365.isBrowser) {
        return (
            <Module {...ProductCollectionContainer}>
                {heading}
                {isCarousel ? _renderCarousel(SingleSlideCarouselComponentProps, products) : _renderGrid(GridComponentProps, products)}
            </Module>
        );
    }
    props.context.telemetry.warning('Product collection content is empty, module wont render.');
    return null;
};

const _renderCarousel = (carouselContainer: INodeProps, items: IProductComponentViewProps[]): JSX.Element => {
    return <Node {...carouselContainer}>{items && items.map(_renderProduct)}</Node>;
};

const _renderGrid = (gridContainer: INodeProps, items: IProductComponentViewProps[]): JSX.Element => {
    return <Node {...gridContainer}>{items && items.map(_renderProduct)}</Node>;
};

const _renderProduct = (product: IProductComponentViewProps): JSX.Element => {
    const { ProductContainer, productComponent, hasCTAButton, productUrl } = product;
    if (!MsDyn365.isBrowser) {
        return <></>;
    }
    return (
        <Node {...ProductContainer}>
            {productComponent}
            {hasCTAButton && (
                <Button className='msc-cta__primary' href={productUrl} title='Shop'>
                    Shop
                </Button>
            )}
        </Node>
    );
};

export default ProductCollectionView;
